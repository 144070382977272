:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-space-space-unit: 8px;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-radius-radius-round: 50%;
  --dl-size-size-maxcontent: 1414px;
  --dl-space-space-halfunit: 4px;
  --dl-space-space-tenunits: 80px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 40px;
  --dl-space-space-fourunits: 32px;
  --dl-radius-radius-radius24: 24px;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-tripleunit: 24px;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Raleway;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  font-family: Raleway;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Raleway;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.Section-Text {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.SmallCard-Heading {
  font-size: 18px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Text {
  font-size: 16px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.BigCard-Heading {
  font-size: 36px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Heading {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Anchor {
  font-size: 18px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: capitalize;
  text-decoration: none;
}
.Section-Heading {
  font-size: 48px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
.scroll {
  overflow-y: scroll;
}
input[type="radio"] {
  transform: scale(1.5);
}
